<template>
  <div class="header-bar container-full">
    <div class="head__menu container-full">
      <div class="container container-fhd">
        <img
          src="@/assets/img/digital-pharma-team-logo.svg"
          alt="logo"
          class="head__logo"
          @click="logoClick()"
        >
        <div
          class="menu__toggle"
          @click="menuToggle()"
        >
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <scrollactive
          active-class="active"
          :offset="80"
          :duration="500"
          :modify-url="false"
          :exact="true"
          bezier-easing-value="1,1,0,0"
          class="head__links"
        >
          <div class="internal-links">
            <a href="#who_we_are" @click="scrollToSection" class="scrollactive-item">
              Home
            </a>
            <a href="#what_we_do" @click="scrollToSection" class="scrollactive-item">
              What we do
            </a>
            <a href="#how_we_do" @click="scrollToSection" class="scrollactive-item">
              How we do
            </a>
<!--            <a href="#clients" @click="scrollToSection" class="scrollactive-item">-->
<!--              Clients-->
<!--            </a>-->
            <a href="#get_started" @click="scrollToSection" class="scrollactive-item">
              Get Started
            </a>
          </div>
          <div class="pi-link">
<!--            <a href="https://www.gene.com/download/pdf/vabysmo_prescribing.pdf" target="_blank" @click="piClick">-->
<!--              Prescribing Information-->
<!--            </a>-->
            <a href="#contact_us" @click="scrollToSection" class="scrollactive-item btn btn-blue">
              Contact Us
            </a>
          </div>
        </scrollactive>
      </div>
    </div>
  </div>
</template>

<script>
import LockBodyScroll from "@/mixins/LockBodyScroll";
// import DataLayer from "@/mixins/DataLayer";

export default {
  name: "HeaderBar",
  mixins: [
    LockBodyScroll
    // , DataLayer
  ],
  methods: {
    menuToggle() {
      if (this.$el.classList.contains('menu__opened')) {
        this.menuClose()
      } else {
        this.menuOpen()
      }
      // this.clickEventPush("Mobile menu")
    },
    menuClose() {
      this.$el.classList.remove('menu__opened');
      document.body.classList.remove('menu__opened')
      this.$el.querySelector('.head__links').classList.remove('opened');
      this.$el.querySelector('.menu__toggle').classList.remove('open')
      this.unLockBodyScroll();
    },
    menuOpen() {
      this.activeMenuEl = this.$el.querySelector('a.active')
      this.$el.classList.add('menu__opened')
      document.body.classList.add('menu__opened')
      this.$el.querySelector('.head__links').classList.add('opened');
      this.$el.querySelector('.menu__toggle').classList.add('open')
      this.lockBodyScroll()
      if (this.activeMenuEl) {
        setTimeout(() => {
          this.$el.querySelector('a.active').classList.remove('active')
          this.activeMenuEl.classList.add('active')
        }, 200)
      }

    },
    scrollToY(y) {
      window.scrollTo({top: y, behavior: 'smooth'});
      setTimeout(() => {
        this.listenPageScroll()
      }, 800)
    },
    logoClick() {
      this.scrollToY(0)
      // this.clickEventPush("vabysmo logo (header)")
    },
    piClick() {
      // this.clickEventPush("Prescribing Information")
    },
    scrollToSection(e) {
      e.preventDefault()
      e.stopImmediatePropagation()
      let timeout = 0
      if (this.$el.classList.contains('menu__opened')) {
        timeout = 500;
        this.menuClose()
      }
      let el   = e.target
      let elID = el.getAttribute('href')
      if (!elID) {
        el   = e.target.parentElement
        elID = el.getAttribute('href')
      }
      const top  = document.querySelector(elID).offsetTop
      setTimeout(() => {
        this.scrollToY(top)
      }, timeout)

      // if (typeof window.dataLayer !== 'undefined' && window.dataLayer) {
      //   window.dataLayer.push({
      //     event: "section-click",
      //     section: el.innerText,
      //     subsection: "-",
      //   });
      // }
    },
    listenPageScroll() {
      if (window.scrollY > 28) {
        this.$el.classList.add('show-logo')
        document.body.classList.add('show-menu-logo')
        if (window.scrollY > this.oldScroll
            || (this.$el.classList.contains('hide-wrapper') && window.scrollY === this.oldScroll)) {
          if (
              !document.getElementById('app').classList.contains('screentest')
              && window.scrollY > this.oldScroll + 4
          ) {
            this.$el.classList.add('hide-wrapper')
          }
        } else if (this.oldScroll > window.scrollY + 4) {
          this.$el.classList.remove('hide-wrapper')
        }
      } else {
        this.$el.classList.remove('show-logo')
        document.body.classList.remove('show-menu-logo')
        this.$el.classList.remove('hide-wrapper')
      }
      this.oldScroll = window.scrollY;
    }
  },
  mounted() {
    this.oldScroll = window.scrollY
    window.onscroll = () => {
      this.listenPageScroll()
    }
    window.addEventListener('orientationchange', () => {
      if (this.$el.classList.contains('menu__opened')) {
        this.menuClose()
      }
    })
  }
}
</script>

<style scoped lang="scss">
.header-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 1;
  transition: 0.5s ease;
  font-family: $second-font;

  &.menu__opened {
    @media (max-width: 1024px) {
      .head__menu {
        background: #000;
      }
    }
  }

  &.hide-wrapper {
    top: -90px;
    opacity: 0;

    @media (max-width: $mobile-max) {
      top: -90px;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .brand__top {
    background: #fff;

    .container {
      height: 38px;
      justify-content: flex-end;

      @media (max-width: $mobile-max) {
        justify-content: center;
      }

      img {
        max-height: 18px;
        margin-right: 24px;
        margin-bottom: -2px;
      }
    }
  }

  .head__menu {
    background: rgb(0 0 0 / 90%);
    transition: 0.5s ease;

    @media (min-width: 768px) {
      body:not(.scrolled) & {
        background: rgb(0 0 0 / 0%);
      }
    }

    #app.screentest & {
      background: rgb(0 0 0 / 90%);
    }

    .container {
      padding-top: 4px;
      padding-bottom: 4px;

      .head__logo {
        max-height: 48px !important;
        margin: 11px 0;
        transition: 0.5s ease;
        cursor: pointer !important;

        @media (max-width: $mobile-max) {
          max-height: 36px !important;
          margin: 6px 0;
        }
      }

      .menu__toggle {
        position: relative;
        width: 40px;
        height: 32px;

        @media (min-width: 768px) {
          display: none;
        }

        span {
          display: block;
          position: absolute;
          height: 3px;
          width: 50%;
          background: $text-egg;
          opacity: 1;
          transform: rotate(0deg);
          transition: 0.25s ease-in-out;

          &:nth-child(even) {
            left: 50%;
          }

          &:nth-child(odd) {
            left: 0;
          }

          &:nth-child(1),
          &:nth-child(2) {
            top: 3px;
          }

          &:nth-child(3),
          &:nth-child(4) {
            top: 14px;
          }

          &:nth-child(5),
          &:nth-child(6) {
            top: 26px;
          }
        }

        &.open {
          span {
            &:nth-child(1),
            &:nth-child(6) {
              transform: rotate(45deg);
            }

            &:nth-child(2),
            &:nth-child(5) {
              transform: rotate(-45deg);
            }

            &:nth-child(1) {
              left: 5px;
              top: 12px;
            }

            &:nth-child(2) {
              left: calc(50% - 5px);
              top: 12px;
            }

            &:nth-child(3) {
              left: -50%;
              opacity: 0;
            }

            &:nth-child(4) {
              left: 100%;
              opacity: 0;
            }

            &:nth-child(5) {
              left: 5px;
              top: 22px;
            }

            &:nth-child(6) {
              left: calc(50% - 5px);
              top: 22px;
            }
          }
        }
      }

      .head__links {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;

        @media (min-width: 768px) {
          padding-left: 40px;

          .internal-links {
            margin-right: 1.5rem;
          }
        }

        a {
          font-weight: 700;
          color: #fcf9da;
          text-decoration: none !important;
          transition: 0.3s ease;

          &:not(.btn) {
            font-size: 14px;
          }

          &:not(:last-of-type) {
            margin-right: 1.5rem;
          }

          &.active:not(.btn) {
            color: $text-yellow;
          }

          @media (min-width: 1025px) {
            &:hover:not(.btn) {
              color: $text-yellow;
              text-decoration: underline !important;
            }
          }
        }

        @media (max-width: 767px) {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100vw;
          height: 80vh;
          max-height: 0;
          transition: 0.5s ease;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding: 0;

          .internal-links {
            display: flex;
            flex-direction: column;
            margin: 40px 0 20px;
          }

          a {
            font-size: 16px !important;
            line-height: 40px;
            margin: 0 30px;
          }

          &.opened {
            height: 100vh;
            background: rgb(0 0 0 / 95%);
            max-height: calc(100vh - 55px);
            overflow: auto;
            padding-bottom: 100px;
          }

          .pi-link {
            @media (max-width: 767px) {
              margin-top: 30px;
              padding-bottom: 15px;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}

</style>