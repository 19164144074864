<template>
  <div
    id="what_we_do"
    class="what-we-do container-full  flex flex-col justify-around"
    :class="{'screentest': $store.state.Screentest.isScreentest}"
  >
    <div class="tabs container">
      <h1
        class="fade-in"
        :class="startAnimation1 ? 'fade-in-start' : 'fade-in-wait'"
      >
        What We Do
      </h1>
      <div v-if="UserAgent.window.width < 801" class="tabs-selectors">
        <div class="tabs-selector" @click="selectTab(1)" :class="tab === 1 ? 'active' : ''">
          Web Development
        </div>
        <div class="tabs-selector" @click="selectTab(2)" :class="tab === 2 ? 'active' : ''">
          Video Production
        </div>
      </div>
      <div
        class="tabs-content"
      >
        <div
          class="tab-content tab-1 fade-from-bottom"
          :class="{active: UserAgent.window.width > 800 || tab === 1, 'fade-from-bottom-start': startAnimation2, 'fade-from-bottom-wait': !startAnimation2}"
        >
          <h3 v-if="UserAgent.window.width > 800">
            Web Development
          </h3>
          <ul>
            <li>E-detailer</li>
            <li>Landing pages</li>
            <li>SPA & Web Applications</li>
            <li>Media content (emails, banners)</li>
          </ul>
        </div>
        <div
          style="transition-delay: 0.8s;"
          class="tab-content tab-2 fade-from-bottom"
          :class="{active: UserAgent.window.width > 800 || tab === 2, 'fade-from-bottom-start': startAnimation2, 'fade-from-bottom-wait': !startAnimation2}"
        >
          <h3 v-if="UserAgent.window.width > 800">
            Video Production
          </h3>
          <ul>
            <li>KOL + Overlays</li>
            <li>Animated Clinical Video</li>
            <li>Product Video Explainer</li>
            <li>Character Animation Promo</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
// import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export default {
  name: "WhatWeDoSection",
  data() {
    return {
      tab: 1,
      startAnimation1: false,
      startAnimation2: false
    };
  },
  computed: {
    ...mapState(["UserAgent"]),
  },
  methods: {
    selectTab(tabNum) {
      this.tab = tabNum
    },
    initAnimations() {
      ScrollTrigger.create({
        trigger: ".what-we-do h1",
        start: "top bottom-=10%",
        onEnter: () => {
          this.startAnimation1 = true;
        },
      });

      ScrollTrigger.create({
        trigger: ".tabs-content",
        start: "top bottom-=10%",
        onEnter: () => {
          this.startAnimation2 = true;
        },
      });
    }
  },
  mounted() {
    setTimeout(this.initAnimations, 1500)
  },
};
</script>

<style lang="scss">
.what-we-do {
  position: relative;
  overflow: hidden;
  background-image: url("~@/assets/img/bg-what-we-do.jpg");
  background-size: 100% 100%;
  padding-bottom: 100px;
  padding-top: 80px;

  /* @media (min-width: 1025px) {
    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgb(0 0 0 / 0%);
      transition: $fade-transition;

      body:not(.wwd-active) & {
        background: $fade-color;
      }
    }
  } */

  @media (max-width: 600px) {
    padding-bottom: 60px;
    padding-top: 20px;
  }

  h1 {
    color: $text-egg !important;
  }

  .tabs {
    &-selectors {
      display: flex;
    }

    &-selector {
      width: 50%;
      padding: 20px;
      font-size: 18px;
      transition: 0.3s ease;
      cursor: pointer;

      &.active {
        border-radius: 8px 8px 0 0;
        box-shadow: 0 -30px 30px rgb(0 0 0 / 20%);
        background: rgb(255 255 255 / 10%);
      }

      &:not(.active) {
        color: rgb(255 255 255 / 70%);
      }
    }

    &-content {
      display: flex;
      justify-content: space-between;

      & > div {
        width: 47%;
        padding: 40px 50px;
        box-shadow: 0 0 100px rgb(0 0 0 / 30%);

        @media (max-width: 800px) {
          position: relative;
          min-width: 100%;
          max-width: 100%;
          opacity: 0;
          padding: 20px;
          box-shadow: 0 30px 30px rgb(0 0 0 / 20%);
          border-radius: 0 0 8px 8px;
          background: rgb(255 255 255 / 10%);
          z-index: -1;
          transition-delay: 0s !important;

          &.tab-2 {
            margin-left: -100%;
          }

          &.active {
            z-index: 1;
            opacity: 1;
          }
        }

        li {
          font-family: $museo-font;
          font-size: 18px;
          padding-bottom: 16px;
          margin-bottom: 16px;
          border-bottom: 1px solid rgb(255 255 255 / 50%);

          @media (max-width: 600px) {
            font-size: 16px;
            padding-bottom: 12px;
            margin-bottom: 12px;
            border-bottom: 1px solid rgb(255 255 255 / 20%);
          }
        }
      }
    }
  }
}
</style>