import Vue from "vue";
import Vuex from "vuex";
import Screentest from "@/store/modules/Screentest";
import UserAgent from "@/store/modules/UserAgent";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    rotateLock: null,
  },
  mutations: {
    SET_ROTATE_LOCK(state, value) {
      state.rotateLock = value;
    },
  },
  actions: {},
  modules: {
    Screentest,
    UserAgent,
  },
  plugins: [],
});
