<template>
  <div
    id="app"
    :class="[
      { 'screentest': $store.state.Screentest.isScreentest },
      browser.name.toLowerCase(),
      browser.os.toLowerCase(),
    ]"
  >
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex"

import { detect } from "detect-browser"

import { NoParentScrollMixin } from "./mixins/NoParentScrollMixin"
import HandleOrientation from "./mixins/HandleOrientation"

export default {
  name: "App",
  components: {
  },
  mixins: [NoParentScrollMixin, HandleOrientation],
  data() {
    return {
      browser: null,
    };
  },
  computed: {
    ...mapState(["UserAgent"]),
  },
  watch: {
    $route() {
      /**
       * Reset (set to initial state)
       */
    },
  },
  methods: {
    calcVH() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    },
  },
  created() {
    if (!!this.$route.query.screentest) {
      this.$store.dispatch("setScreentest")
    }

    const browser = detect()
    if (browser) {
      this.browser = browser
    }
  },
  mounted() {
    this.calcVH()

    const isScreenTest = window.location.href.toLowerCase().indexOf('screentest') > -1;
    
    if(!isScreenTest) {
      window.addEventListener('resize', () => {
        this.$store.commit('UPDATE_RESIZES')
      })
    }
    this.$store.commit('CHECK_IS_MOBILE_DEVICE')
  },
}
</script>

<style lang="scss" src="./scss/main.scss"></style>
