var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"get-started container-full flex",class:{'screentest': _vm.$store.state.Screentest.isScreentest},attrs:{"id":"get_started"}},[_c('div',{staticClass:"container-full flex flex-1 flex-col justify-around"},[_c('div',{staticClass:"container"},[(_vm.UserAgent.window.width > 767)?_c('div',{staticClass:"results"},[_c('h2',[_vm._v(" Get Result in 3 Steps ")]),_c('div',{staticClass:"row row-num"},[_c('div',{staticClass:"col col-0"}),_c('div',{staticClass:"col col-1"},[_c('span',[_vm._v(" Step 1 ")]),_c('img',{staticClass:"fade-from-right",class:_vm.startAnimation ? 'fade-from-right-start' : 'fade-from-right-wait',staticStyle:{"transition-delay":"1s"},attrs:{"src":require("@/assets/img/result-1.svg"),"alt":""}})]),_c('div',{staticClass:"col col-2"},[_c('span',[_vm._v(" Step 2 ")]),_c('img',{staticClass:"fade-from-right",class:_vm.startAnimation ? 'fade-from-right-start' : 'fade-from-right-wait',staticStyle:{"transition-delay":"1.4s"},attrs:{"src":require("@/assets/img/result-2.svg"),"alt":""}})]),_c('div',{staticClass:"col col-3"},[_c('span',[_vm._v(" Step 3 ")]),_c('img',{staticClass:"fade-from-right",class:_vm.startAnimation ? 'fade-from-right-start' : 'fade-from-right-wait',staticStyle:{"transition-delay":"1.8s"},attrs:{"src":require("@/assets/img/result-3.svg"),"alt":""}})]),_c('div',{staticClass:"col col-0"})]),_vm._m(0),_vm._m(1)]):_c('div',{staticClass:"results"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row row-text"},[_c('div',{staticClass:"col col-0"}),_c('div',{staticClass:"col col-1"},[_c('h3',[_vm._v(" Contact Us ")])]),_c('div',{staticClass:"col col-2"},[_c('h3',[_vm._v(" Participate in the initial meeting ")])]),_c('div',{staticClass:"col col-3"},[_c('h3',[_vm._v(" Let our management handle the rest for you ")])]),_c('div',{staticClass:"col col-0"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row row-text"},[_c('div',{staticClass:"col col-0"}),_c('div',{staticClass:"col col-1"},[_c('p',[_vm._v(" Share your needs with us, and the more you tell us, the better we can provide on the first call. ")])]),_c('div',{staticClass:"col col-2"},[_c('p',[_vm._v(" During our initial call, we will clarify incoming data and propose the optimal strategy to achieve your goals. ")])]),_c('div',{staticClass:"col col-3"},[_c('p',[_vm._v(" We can independently handle the development process, communicating either with your customer service or directly with the client, acting as a representative of your company. ")])]),_c('div',{staticClass:"col col-0"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-row m-row-1"},[_c('div',{staticClass:"m-col col-1"}),_c('div',{staticClass:"m-col col-2"},[_c('h2',[_vm._v("Get Result"),_c('br'),_vm._v("in 3 Steps")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-row m-row-2"},[_c('div',{staticClass:"m-col col-1"},[_vm._v(" Step 1 "),_c('img',{attrs:{"src":require("@/assets/img/result-1.svg"),"alt":""}})]),_c('div',{staticClass:"m-col col-2"},[_c('h3',[_vm._v("Contact Us")]),_c('p',[_vm._v("Share your needs with us, and the more you tell us, the better we can provide on the first call.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-row m-row-3"},[_c('div',{staticClass:"m-col col-1"},[_vm._v(" Step 2 "),_c('img',{attrs:{"src":require("@/assets/img/result-2.svg"),"alt":""}})]),_c('div',{staticClass:"m-col col-2"},[_c('h3',[_vm._v("Participate in the initial meeting")]),_c('p',[_vm._v("During our initial call, we will clarify incoming data propose the optimal strategy to achieve your goals.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-row m-row-4"},[_c('div',{staticClass:"m-col col-1"},[_vm._v(" Step 3 "),_c('img',{attrs:{"src":require("@/assets/img/result-3.svg"),"alt":""}})]),_c('div',{staticClass:"m-col col-2"},[_c('h3',[_vm._v("Relax and let us handle project execution")]),_c('p',[_vm._v("We can independently handle the development process, communicating either with your customer service or directly with the client, acting as a representative of your company.")])])])
}]

export { render, staticRenderFns }