<template>
  <div
    id="clients"
    class="clients container-full flex"
    :class="{'screentest': $store.state.Screentest.isScreentest}"
  >
    <div class="container-full flex flex-1 flex-col justify-around">
      <div class="container">
        <img
          style="transition-delay: 0.8s;"
          class="head fade-in"
          :class="startAnimation ? 'fade-in-start' : 'fade-in-wait'"
          src="@/assets/img/head-draw.svg" alt=""
        >
        <div class="heading">
          <h1
            class="fade-in"
            :class="startAnimation ? 'fade-in-start' : 'fade-in-wait'"
          >
            Brands We’ve<br>Worked With
          </h1>
        </div>
        <div
          class="clients-logos-wrapper fade-from-bottom"
          :class="startAnimation ? 'fade-from-bottom-start' : 'fade-from-bottom-wait'"
        >
          <div class="controller">
            <div
              v-for="n in sectionsCount"
              @click="viewSection(n)"
              :class="section === n ? 'active' : ''"
              :key="n"
            />
          </div>
          <div class="clients-logos">
            <div
              class="clients-logos-panel"
              ref="sections"
              :style="{width: panelWidth + '%'}"
            >
              <div
                v-for="logo in logos"
                :key="logo.i"
                class="logo"
                :style="{width: logoWidth + '%', paddingTop: logoWidth + '%'}"
              >
                <img :src="getLogoUrl(logo.uri)">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export default {
  name: "ClientsSection",
  data() {
    return {
      startAnimation: false,
      section: 1,
      logosInSection: 16,
      logosInCol: 4,
      logosInRow: 4,
      logosCount: 0,
      panelWidth: 0,
      logoWidth: 0,
      // logoHeight: 0,
      sectionsCount: 0,
      autoSlide: null,
      autoSlideIntervalMS: 8000,
      logos: [
        "client-logo-11.png",
        "client-logo-12.png",
        "client-logo-13.png",
        "client-logo-14.png",
        "client-logo-15.png",
        "client-logo-16.png",
        "client-logo-17.png",
        "client-logo-18.png",
        "client-logo-19.png",
        "client-logo-20.png",
        "client-logo-21.png",
        "client-logo-22.png",
        "client-logo-23.png",
        "client-logo-24.png",
        "client-logo-25.png",
        "client-logo-26.png",
        "client-logo-27.png",
        "client-logo-28.png",
        "client-logo-29.png",
        "client-logo-30.png",
        "client-logo-31.png",
        "client-logo-32.png",
        "client-logo-33.png",
        "client-logo-34.png",
        "client-logo-35.png",
        "client-logo-36.png",
        "client-logo-37.png",
        "client-logo-38.png",
        "client-logo-39.png",
        "client-logo-40.png",
        "client-logo-41.png",
        "client-logo-42.png",
        "client-logo-43.png",
        "client-logo-44.png",
        "client-logo-45.png",
        "client-logo-46.png",
        "client-logo-47.png",
        "client-logo-48.png",
        "client-logo-49.png",
        "client-logo-50.png",
        "client-logo-51.png",
        "client-logo-52.png",
        "client-logo-53.png",
        "client-logo-54.png",
        "client-logo-55.png",
        "client-logo-56.png",
        "client-logo-57.png",
        "client-logo-58.png",
        "client-logo-59.png",
        "client-logo-60.png",
        "client-logo-61.png",
        "client-logo-62.png",
        "client-logo-63.png",
        "client-logo-64.png",
        "client-logo-65.png",
        "client-logo-66.png",
        "client-logo-67.png",
        "client-logo-68.png",
        "client-logo-69.png",
        "client-logo-70.png",
        "client-logo-71.png",
        "client-logo-72.png",
        "client-logo-73.png",
        "client-logo-74.png",
        "client-logo-75.png",
        "client-logo-76.png",
        "client-logo-77.png",
      ],
      swipeProps: {
        touchObj: null,
        startX: 0,
        startY: 0,
        dist1: 0,
        dist2: 0,
        threshold: 5, //required min distance traveled to be considered swipe
        allowedTime: 5000, // maximum time allowed to travel that distance
        elapsedTime: 0,
        startTime: 0
      }
    };
  },
  computed: {
    ...mapState(["UserAgent"]),
  },
  methods: {
    prepareLogos() {
      this.logosCount = this.logos.length
      this.sectionsCount = Math.ceil(this.logosCount / this.logosInSection)
      this.panelWidth = (100 * (this.sectionsCount - 1)) + (100 / this.logosInRow * Math.ceil((this.logosCount - ((this.sectionsCount - 1) * this.logosInRow * this.logosInCol)) / this.logosInCol))
      this.logoWidth = 100 / this.logosInRow / (this.panelWidth / 100)
      // this.logoHeight = 100 / this.logosInCol

      console.log('this.sectionsCount ' + this.sectionsCount)

      this.logos.forEach((logo, i) => {
        this.logos[i] = {
          i: i + 1,
          uri: logo
        }
      })
    },
    getLogoUrl(logo) {
      return require(`@/assets/img/clients-v2/${logo}`);
    },
    viewSection(num) {
      this.stopAutoSlide()
      this.section = num
      if (num === 1) {
        this.$refs.sections.style.left = 0
      } else {
        this.$refs.sections.style.left = num === this.sectionsCount ?
            '-' + (this.panelWidth - 100) + '%'  : '-' + (100 * (num - 1)) + '%'
      }
      this.startAutoSlide()
    },
    stopAutoSlide() {
      clearInterval(this.autoSlide)
    },
    startAutoSlide() {
      this.autoSlide = setInterval(() => {
        this.nextSection()
      }, this.autoSlideIntervalMS)
    },
    initAnimations() {
      ScrollTrigger.create({
        trigger: this.$el,
        start: "top bottom-=10%",
        onEnter: () => {
          this.startAnimation = true;
          this.stopAutoSlide()
          this.startAutoSlide()
        },
      });
    },
    swipeInit() {
      const el = document.querySelector('.clients-logos-panel')
      el.addEventListener('touchstart', this.swipeSetTouchStart)
      el.addEventListener('touchend', this.swipeSetTouchEnd)
      el.addEventListener('touchmove', (e) => {
        e.preventDefault() // prevent scrolling when inside DIV
      })
    },
    swipeSetTouchStart(e) {
      // e.preventDefault()
      this.swipeProps.touchObj = e.changedTouches[0]
      this.swipeProps.dist1 = 0
      this.swipeProps.dist2 = 0
      this.swipeProps.startX = this.swipeProps.touchObj.pageX
      this.swipeProps.startY = this.swipeProps.touchObj.pageY
      this.swipeProps.startTime = new Date().getTime() // record time when finger first makes contact with surface
    },
    swipeSetTouchEnd(e) {
      // e.preventDefault()
      this.swipeProps.touchObj = e.changedTouches[0]
      this.swipeProps.dist1 = this.swipeProps.touchObj.pageX - this.swipeProps.startX // get total dist traveled by finger while in contact with surface
      this.swipeProps.dist2 = this.swipeProps.startX - this.swipeProps.touchObj.pageX
      this.swipeProps.elapsedTime = new Date().getTime() - this.swipeProps.startTime // get time elapsed
      this.swipeHandle()
    },
    swipeHandle() {
      // check that elapsed time is within specified, horizontal dist traveled >= threshold, and vertical dist traveled <= 100
      if (this.swipeProps.elapsedTime <= this.swipeProps.allowedTime
        && Math.abs(this.swipeProps.touchObj.pageY - this.swipeProps.startY) <= 200) {
        if (this.swipeProps.dist1 >= this.swipeProps.threshold) {
          if (this.section !== 1) {
            this.prevSection()
          }
        } else if (this.swipeProps.dist2 >= this.swipeProps.threshold) {
          if (this.section !== this.sectionsCount) {
            this.nextSection()
          }
        }
      }
    },
    nextSection() {
      let newSection = this.section === this.sectionsCount ? 1 : this.section + 1
      this.viewSection(newSection)
    },
    prevSection() {
      let newSection = this.section === 1 ? this.sectionsCount : this.section - 1
      this.viewSection(newSection)
    }
  },
  beforeMount() {
    this.prepareLogos()
  },
  mounted() {
    setTimeout(this.initAnimations, 1500)
    this.swipeInit()
  },
};
</script>

<style lang="scss">
/* stylelint-disable */
.clients {
  position: relative;
  background-image: url("~@/assets/img/bg-clients.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 120px 0;
  overflow: hidden;
  box-shadow: 0 0 100px rgb(0 0 0 / 100%);

  /* @media (min-width: 1025px) {
    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgb(0 0 0 / 0%);
      transition: $fade-transition;

      body:not(.clients-active) & {
        background: $fade-color;
      }
    }
  } */

  /* @media (min-width: 1025px) {
    position: sticky;
    top: 0;
    min-height: 100vh;
    max-height: 100vh;
    overflow: auto;
  } */

  @media (max-width: 767px) {
    padding: 80px 0;
  }

  & > * > .container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 600px) {
      flex-direction: column;
    }

    img.head {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 15%;

      @media (max-width: 600px) {
        display: none;
      }
    }

    .heading {
      width: 45%;

      @media (min-width: 601px) and (max-width: 900px) {
        width: 35%;
      }

      @media (max-width: 600px) {
        width: 100%;
      }

      h1 {
        color: #2f2f2f;
        text-align: center;
        margin: 0;

        @media (max-width: 600px) {
          margin-bottom: 70px;
        }
      }
    }

    .controller {
      display: flex;
      position: absolute;
      bottom: 10px;
      left: 50%;
      width: fit-content;
      height: 20px;
      gap: 10px;
      transform: translateX(-50%);

      & > * {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background: rgb(0 0 0 / 30%);
        transition: 0.2s ease;
        cursor: pointer;

        &:hover,
        &.active {
          background: rgb(0 0 0 / 70%);
        }
      }
    }

    .clients-logos {
      position: absolute;
      top: 5%;
      left: 5%;
      height: 90%;
      width: 90%;
      overflow: hidden;

      @media (max-width: 600px) {
        top: 20px;
        left: 20px;
        width: calc(100% - 40px);
        padding-top: calc(100% - 40px);
      }

      &-wrapper {
        position: relative;
        width: 50%;
        padding-top: 50%;
        background: rgb(255 255 255 / 70%);

        @media (min-width: 601px) and (max-width: 900px) {
          width: 62%;
          padding-top: 62%;
        }

        @media (max-width: 600px) {
          width: 100%;
          padding-top: 100%;
        }
      }

      &-panel {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        min-height: 100%;
        max-height: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
        transition: left 0.75s ease;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;

        .logo {
          position: relative;
          transition: 0.3s ease;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 90%;
            max-height: 80%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}
/* stylelint-enable */
</style>