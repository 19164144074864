<template>
  <div class="slide-smoother container-full" />
</template>

<script>

export default {
  name: "SlideSmootherSection",
};
</script>

<style lang="scss" scoped>
.slide-smoother {
  min-height: 30vh;
  max-height: 30vh;
  position: sticky;
  z-index: -1;
  top: 0;

  @media (max-width: 1024px) {
    display: none !important;
  }
}
</style>