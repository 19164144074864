<template>
  <div
    id="how_we_do_3"
    class="how-we-do container-full flex"
    :class="{'screentest': $store.state.Screentest.isScreentest}"
  >
    <div class="container-full el el-3 flex flex-1 flex-col justify-around">
      <div class="container">
        <img
          class="anim3 fade-from-left"
          :class="startAnimation ? 'fade-from-left-start' : 'fade-from-left-wait'"
          src="@/assets/img/img-how-we-do-3.png"
          alt=""
        >
        <div
          style="transition-delay: 0.2s;"
          class="anim3 fade-from-right"
          :class="startAnimation ? 'fade-from-right-start' : 'fade-from-right-wait'"
        >
          <div class="flex flex-col width-auto">
            <h2>Integration<br>and Processes</h2>
            <p><span class="text-2x text-blue">Reputation</span> built over the years</p>
            <p><span class="text-2x text-blue">Early Estimates</span> and team deployment</p>
            <p>Consistent quality and <span class="text-2x text-blue">Error-Free</span> execution at all stages</p>
            <p>Asset preparation for <span class="text-2x text-blue">MLR Submission</span></p>
            <p><span class="text-2x text-blue">Seamless Integration</span> into your workflow</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export default {
  name: "HowWeDoSection3",
  data() {
    return {
      startAnimation: false,
    };
  },
  computed: {
    ...mapState(["UserAgent"]),
  },
  methods: {
    initAnimations() {

      ScrollTrigger.create({
        trigger: ".anim3",
        start: "top bottom-=20%",
        onEnter: () => {
          this.startAnimation = true;
        },
      });
    }
  },
  mounted() {
    setTimeout(this.initAnimations, 1500)
  },
};
</script>

<style lang="scss" scoped>
.how-we-do {
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 30px rgb(0 0 0 / 100%);

  @media (min-width: 1025px) {
    min-height: calc(100vh - var(--hwd3offset));
  }

  /* @media (min-width: 1025px) {
    transition: 0.3s ease-in-out;

    body:not(.hwd3-active):not(.clients-active) & {
      opacity: 0;
    }

    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgb(0 0 0 / 0%);
      transition: $fade-transition;

      body:not(.hwd3-active) & {
        background: $fade-color;
      }
    }
  } */

  /* @media (min-width: 1025px) {
    position: sticky;
    top: 150px;
    min-height: calc(100vh - 150px);
    max-height: calc(100vh - 150px);
    overflow: auto;
  } */

  @media (min-width: 601px) {
    .mobile-br {
      display: none;
    }
  }

  .el {
    @media (max-width: 600px) {
      position: relative;
      background-size: cover !important;
      background-position: center center !important;
      background-repeat: no-repeat !important;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      & > div {
        max-width: 50%;
        min-width: 50%;

        @media (min-width: 1200px) and (min-height: 720px) {
          &.fade-from-right-start {
            transform: scale(1.2) translateX(-10%) !important;
          }

          &.fade-from-left-start {
            transform: scale(1.2) translateX(10%) !important;
          }
        }

        p {
          margin-bottom: 0.7em;
        }

        @media (min-width: 601px) and (max-width: 767px) {
          max-width: 65%;
          min-width: 65%;
        }

        @media (max-width: 600px) {
          max-width: 100%;
          min-width: 100%;

          p {
            font-size: 16px;
            margin-bottom: 12px;
          }

          .text-2x {
            font-size: 20px;
          }
        }

        .flex-p {
          display: flex;
          flex-direction: row;
          align-items: center;

          & > *:first-child {
            margin-right: 0.3em;
          }
        }
      }

      & > img {
        max-width: 30%;
        margin: 0 auto;

        @media (max-width: 767px) {
          margin: 0;
        }

        @media (max-width: 600px) {
          position: absolute;
        }
      }
    }

    &-1 {
      padding: 74px 0 37px;
      background: url("~@/assets/img/bg-how-we-do-1.jpg"), #000;
      background-size: 100% 100%;

      @media (max-width: 600px) {
        padding-top: 100px;

        img {
          right: 7%;
          top: 40px;
          transform: rotateY(180deg);
        }
      }
    }

    &-2 {
      padding: 37px 0;
      background: url("~@/assets/img/bg-how-we-do-2.jpg"), #000;
      background-size: 100% 100%;

      @media (max-width: 600px) {
        img {
          right: 5%;
          top: 20%;
          transform: rotateY(180deg);
        }
      }
    }

    &-3 {
      padding: 74px 0;
      background: url("~@/assets/img/bg-how-we-do-3-final.jpg"), #000;
      background-size: 100% 100%;

      @media (min-width: 1025px) {
        .width-auto {
          width: fit-content;
          margin: 0 auto !important;
          float: none !important;
        }

        img {
          max-height: 90%;
        }
      }

      @media (min-width: 601px) {
        .width-auto {
          width: fit-content;
          float: right;
          margin: 0;

          & > * {
            width: auto;
          }
        }
      }

      @media (max-width: 600px) {
        padding: 50px 0;

        img {
          right: 7%;
          top: 30px;
          max-width: 25% !important;
        }
      }
    }
  }
}
</style>