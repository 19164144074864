<template>
  <div
    id="get_started"
    class="get-started container-full flex"
    :class="{'screentest': $store.state.Screentest.isScreentest}"
  >
    <div class="container-full flex flex-1 flex-col justify-around">
      <div class="container">
        <div
          v-if="UserAgent.window.width > 767"
          class="results"
        >
          <h2>
            Get Result in 3 Steps
          </h2>
          <div class="row row-num">
            <div class="col col-0" />
            <div class="col col-1">
              <span >
                Step 1
              </span>
              <img
                src="@/assets/img/result-1.svg"
                alt=""
                style="transition-delay: 1s;"
                class="fade-from-right"
                :class="startAnimation ? 'fade-from-right-start' : 'fade-from-right-wait'"
              >
            </div>
            <div class="col col-2">
              <span>
                Step 2
              </span>
              <img
                style="transition-delay: 1.4s;"
                src="@/assets/img/result-2.svg"
                alt=""
                class="fade-from-right"
                :class="startAnimation ? 'fade-from-right-start' : 'fade-from-right-wait'"
              >
            </div>
            <div
              class="col col-3"
            >
              <span>
                Step 3
              </span>
              <img
                src="@/assets/img/result-3.svg"
                alt=""
                style="transition-delay: 1.8s;"
                class="fade-from-right"
                :class="startAnimation ? 'fade-from-right-start' : 'fade-from-right-wait'"
              >
            </div>
            <div class="col col-0" />
          </div>
          <div class="row row-text">
            <div class="col col-0"></div>
            <div class="col col-1">
              <h3>
                Contact Us
              </h3>
            </div>
            <div class="col col-2">
              <h3>
                Participate in the initial meeting
              </h3>
            </div>
            <div class="col col-3">
              <h3>
                Let our management handle the rest for you
              </h3>
            </div>
            <div class="col col-0"></div>
          </div>
          <div class="row row-text">
            <div class="col col-0"></div>
            <div class="col col-1">
              <p>
                Share your needs with us, and the more you tell us, the better we can provide on the first call.
              </p>
            </div>
            <div class="col col-2">
              <p>
                During our initial call, we will clarify incoming data and propose the optimal strategy to achieve your goals.
              </p>
            </div>
            <div class="col col-3">
              <p>
                We can independently handle the development process, communicating either with your customer service or directly with the client, acting as a representative of your company.
              </p>
            </div>
            <div class="col col-0"></div>
          </div>
        </div>
        <div v-else class="results">
          <div class="m-row m-row-1">
            <div class="m-col col-1" />
            <div class="m-col col-2">
              <h2>Get Result<br>in 3 Steps</h2>
            </div>
          </div>
          <div class="m-row m-row-2">
            <div class="m-col col-1">
              Step 1
              <img src="@/assets/img/result-1.svg" alt="">
            </div>
            <div class="m-col col-2">
              <h3>Contact Us</h3>
              <p>Share your needs with us, and the more you tell us, the better we can provide on the first call.</p>
            </div>
          </div>
          <div class="m-row m-row-3">
            <div class="m-col col-1">
              Step 2
              <img src="@/assets/img/result-2.svg" alt="">
            </div>
            <div class="m-col col-2">
              <h3>Participate in the initial meeting</h3>
              <p>During our initial call, we will clarify incoming data propose the optimal strategy to achieve your goals.</p>
            </div>
          </div>
          <div class="m-row m-row-4">
            <div class="m-col col-1">
              Step 3
              <img src="@/assets/img/result-3.svg" alt="">
            </div>
            <div class="m-col col-2">
              <h3>Relax and let us handle project execution</h3>
              <p>We can independently handle the development process, communicating either with your customer service or directly with the client, acting as a representative of your company.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export default {
  name: "GetStartedSection",
  data() {
    return {
      startAnimation: false
    };
  },
  computed: {
    ...mapState(["UserAgent"]),
  },
  methods: {
    initAnimations() {
      ScrollTrigger.create({
        trigger: this.$el,
        start: "top bottom-=10%",
        onEnter: () => {
          this.startAnimation = true;
        },
      });
    }
  },
  mounted() {
    setTimeout(this.initAnimations, 1500)
  },
};
</script>

<style lang="scss">
.get-started {
  background: #000;
  position: relative;
  padding: 150px 0 100px;
  overflow: hidden;
  box-shadow: 0 0 100px rgb(0 0 0 / 100%);

  /* @media (min-width: 1025px) {
    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgb(0 0 0 / 0%);
      transition: $fade-transition;

      body:not(.get-started-active) & {
        background: $fade-color;
      }
    }
  } */

  @media (max-width: 767px) {
    padding: 80px 0 60px;
  }

  .results {
    position: relative;
    padding: 70px 0 20px;
    background-image: url("~@/assets/img/bg-get-started.png");
    background-size: 100% 100%;
    border-radius: 16px;
    box-shadow: 0 0 20px #0f4c65;
    overflow: hidden;

    @media (max-width: 767px) {
      padding: 0 16px;
      background-image: url("~@/assets/img/bg-get-started-mob.png");
    }

    h2 {
      text-align: center;
      margin-bottom: 80px;
      color: $text-egg !important;
    }

    .row {
      display: flex;
      justify-content: space-between;

      &-num {
        font-family: $museo-font;
        font-size: 24px;
        margin-bottom: 40px;
        border-bottom: 1px solid #fff;

        .col {
          position: relative;
          padding-bottom: 40px;

          img {
            position: absolute;
            bottom: 0;
            left: 18px;
            transform: translate(-50%, 50%);

            &.fade-from-right-wait {
              transform: translate(200%, 50%);
            }

            &.fade-from-right-start {
              transform: translate(-50%, 50%);
            }
          }
        }
      }

      .col {
        display: flex;
        flex-direction: column;

        h3 {
          font-family: $museo-font;
          line-height: 120%;
          font-size: 24px;

          @media (min-width: 1025px) {
            margin-bottom: 12px;
          }
        }

        p {
          font-family: $barlow-font;
          font-size: 16px;
          font-weight: 600;
        }

        &-0 {
          width: 5%;
        }

        &-1 {
          width: 19%;
        }

        &-2 {
          width: 29%;
        }

        &-3 {
          width: 37%;
        }
      }
    }

    .m-row {
      display: flex;

      &-1 {
        .m-col {
          padding-top: 50px;
        }
      }

      &-4 {
        .m-col {
          padding-bottom: 20px;
        }
      }

      .m-col {
        display: flex;
        flex-direction: column;

        h2 {
          text-align: left;
        }

        &.col-1 {
          position: relative;
          width: 90px;
          border-right: 1px solid #fff;
          padding-right: 10px;
          font-size: 20px;
          font-family: $museo-font;
          line-height: 140%;
          align-items: center;

          img {
            position: absolute;
            top: 13px;
            right: 0;
            transform: translate(50%, -50%);
          }
        }

        &.col-2 {
          width: calc(100% - 91px);
          padding-left: 20px;

          h3 {
            font-family: $museo-font;
            margin-bottom: 8px;
          }

          p {
            font-family: $barlow-font;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}
</style>