<template>
  <div
    id="contact_us"
    class="contact-us container-full"
    :class="{'screentest': $store.state.Screentest.isScreentest}"
  >
    <div class="container">
      <div class="flex-container">
        <div class="col col-1">
          <h2
            class="fade-from-left"
            :class="startAnimation ? 'fade-from-left-start' : 'fade-from-left-wait'"
          >
            Step 1.<br>Contact Us
          </h2>
        </div>
        <div class="col col-2">
          <form
            action="#"
            class="fade-from-bottom"
            :class="startAnimation ? 'fade-from-bottom-start' : 'fade-from-bottom-wait'"
          >
            <label for="name">Name</label>
            <input
              id="name"
              v-model="form.name"
              type="text"
              name="name"
              autocomplete="true"
            >
            <label
              for="email"
              class="email-label"
            >Email<i style="color: #f00;">*</i><span
              v-if="incorrectEmail"
              class="incorrect-text"
            >Please, enter a valid email!</span></label>
            <input
              id="email"
              v-model="form.email"
              type="email"
              name="email"
              autocomplete="true"
              :class="{incorrect: incorrectEmail}"
            >
            <label for="message">Message</label>
            <textarea
              id="message"
              v-model="form.message"
              name="message"
            />
            <span class="btns">
              <button
                class="btn btn-blue"
                type="submit"
                @click="sendEmail"
              >Submit</button> <span>or&nbsp;&nbsp;<a
                href="https://calendly.com/digitalpharmateam"
                target="_blank"
              >schedule a meeting</a></span>
            </span>
            <span
              v-if="success"
              class="success"
            >
              <span class="row row-h">
                <span class="success-img">
                  <img
                    src="@/assets/img/success.png"
                    alt="success"
                  >
                </span>
                <span class="success-h">
                  Thank you<br>for contacting us!
                </span>
              </span>
              <span class="row row-b">
                <span class="success-body">
                  <span>
                    We will process your request on the next business day and contact you at your address: <span class="your-email">{{ form.email }}</span>
                  </span>
                </span>
              </span>
              <img
                class="close"
                src="@/assets/img/close.svg"
                alt="close"
                @click="close"
              >
            </span>
          </form>
        </div>
      </div>
      <footer
        style="transition-delay: 0.5s;"
        class="slide-from-bottom"
        :class="startAnimation2 ? 'slide-from-bottom-start' : 'slide-from-bottom-wait'"
      >
        <div class="left">
          <a
            class="mail"
            href="mailto:hello@digitalpharma.team"
          >
            <img
              src="@/assets/img/icon-mail.svg"
              alt=""
            >
            hello@digitalpharma.team
          </a>
          <p class="address">
            8 The Green, Suite #4989<br>Dover Delaware 19901<br>USA
          </p>
          <p class="social">
            <a
              href="https://www.facebook.com/digitalpharmateam"
              target="_blank"
            >
              <img
                src="@/assets/img/icon-fb.svg"
                alt="DPT Facebook"
              >
            </a>
            <a
              href="https://www.linkedin.com/company/digital-pharma-team"
              target="_blank"
            >
              <img
                src="@/assets/img/icon-linked.svg"
                alt="DPT LinkedIn"
              >
            </a>
          </p>
          <p>
            <br>© Digital Pharma Team {{ currentYear }}
          </p>
        </div>
        <img
          src="@/assets/img/NEW-heads-left-lower.png"
          alt=""
          class="head"
        >
      </footer>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import axios from "axios";

export default {
  name: "ContactUsSection",
  data() {
    return {
      currentYear: 0,
      useCaptcha: false,
      success: false,
      error: false,
      startAnimation: false,
      startAnimation2: false,
      incorrectEmail: false,
      form: {
        url: 'https://api.digitalpharma.team',
        name: '',
        email: '',
        message: ''
      },
    };
  },
  computed: {
    ...mapState(["UserAgent"]),
  },
  methods: {
    initAnimations() {
      ScrollTrigger.create({
        trigger: this.$el,
        start: "top bottom-=10%",
        onEnter: () => {
          this.startAnimation = true;
        },
      });

      ScrollTrigger.create({
        trigger: "footer",
        start: "top bottom",
        onEnter: () => {
          this.startAnimation2 = true;
        },
      });
    },
    validateEmail() {
      const pattern = /^[ \t]*[-\w\.]+@[-\w]+\.[-\w]+(\.[A-Za-z]{0,16})*(\.[A-Za-z]{0,16})*[ \t]*\r?$/gi
      return this.form.email.match(pattern) !== null
    },
    clearForm() {
      this.form.name = ''
      this.form.email = ''
      this.form.message = ''
    },
    initRecaptcha() {
      this.reCaptcha = new Promise((resolve, reject) => {
        const $script = document.createElement('script')
        $script.src = 'https://www.google.com/recaptcha/api.js?render=6Ldnt48oAAAAAGbDrXvUvwPp4F-QhCuVnSvJ8BQv'
        resolve(document.head.appendChild($script));
        setTimeout(() => reject(new Error("Google reCaptcha not initialized")), 3000);
      });
    },
    sendEmail(e) {
      e.preventDefault()
      e.stopImmediatePropagation()
      if (!this.validateEmail()) {
        this.incorrectEmail = true
        return
      }

      if (this.useCaptcha) {
        this.sendWithReCaptcha()
      } else {
        this.sendMailToServer('worecap')
      }
    },
    sendMailToServer(token) {
      axios
        .post(this.form.url + "/index.php", {
            token: token,
            email: this.form.email,
            name: this.form.name,
            message: this.form.message
          },
          {headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
          }).then((response) => {
            // console.log(response)
            if (response.data.success) {
              this.success = true
            }
          })
          .catch(() => {
            console.log('POST to API failed!');
          })
    },
    sendWithReCaptcha() {
        this.reCaptcha
          .then(
            result => {
              setTimeout(() => {
                grecaptcha.ready(() => {
                  grecaptcha.execute('6Ldnt48oAAAAAGbDrXvUvwPp4F-QhCuVnSvJ8BQv', {action: 'homepage'}).then((token) => {
                    this.sendMailToServer(token)
                  });
                });
              }, 1000)
            },
            error => {
              console.log(error);
            });
    },
    close() {
      this.clearForm()
      this.success = false
    },
  },
  beforeMount() {
    this.currentYear = new Date().getFullYear()
    this.form.url = window.location.href.indexOf("digitalpharma.team") > -1
        ? "https://api.digitalpharma.team"
        : "https://dptapilanding.test"
  },
  mounted() {
    setTimeout(this.initAnimations, 1500)

    if (this.useCaptcha) {
      setTimeout(this.initRecaptcha, 500)
    }

    document.querySelector('input[name="email"]').addEventListener('focus', () => {
      this.incorrectEmail = false
    })

    if (window.location.href.indexOf('#contact-us') > -1) {
      const checkAndScroll = setInterval(() => {
        const preloader = document.getElementById('preloader-panel')
        if (!preloader || typeof preloader === 'undefined') {
          const offset = this.$el.offsetTop
          window.scrollTo(0, offset, {behavior: "smooth"})
          clearInterval(checkAndScroll)
        }
      }, 500)

    }
  },
};
</script>

<style lang="scss">
.contact-us {
  position: relative;
  background: no-repeat center bottom/contain url("~@/assets/img/1st-bg.jpg"), #000;
  overflow: hidden;

  /* @media (min-width: 1025px) {
    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgb(0 0 0 / 0%);
      transition: $fade-transition;

      body:not(.contact-us-active) & {
        background: $fade-color;
      }
    }
  } */

  .container {
    padding-top: 70px;

    @media (max-width: 767px) {
      padding-top: 50px;
    }
  }

  .flex-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 200px;

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 100px;
    }

    .col {
      display: flex;
      flex-direction: column;

      &-1 {
        width: 45%;

        @media (max-width: 767px) {
          width: 100%;
        }

        h2 {
          text-align: left;
          color: $text-egg !important;
        }
      }

      &-2 {
        width: 50%;

        @media (max-width: 767px) {
          width: 100%;
        }

        form {
          position: relative;
          display: flex;
          flex-direction: column;
          font-family: $second-font;
          padding: 20px;
          border: 1px dashed rgb(255 255 255 / 30%);
          border-radius: 16px;
          background: rgb(255 255 255 / 10%);
          box-shadow: 0 0 20px rgb(1 176 202 / 60%);
          overflow: hidden;

          @media (max-width: 767px) {
            padding: 15px;
          }

          .email-label {
            position: relative;

            & > span {
              position: absolute;
              top: 60px;
              left: 0;
              color: #f00;
              font-size: 0.9em;
            }
          }

          input,
          textarea {
            border: none !important;
            border-radius: 8px;
            padding: 0.5em;
            margin-bottom: 1.5em;
            background: rgb(255 254 239 / 100%);
            transition: 0.3s ease;

            &:focus {
              background: rgb(255 255 255 / 100%);
              outline-color: #00a6c0;
            }

            &.incorrect {
              outline: 2px solid #f00 !important;
            }
          }

          textarea {
            max-width: 100%;
            min-width: 100%;
            min-height: 8em;
          }

          button {
            font-family: $second-font;
            display: flex;
            min-width: 180px;
            max-width: 180px;
            color: #fcf9da;
            justify-content: center;

            @media (max-width: 767px) {
              min-width: 100%;
              max-width: 100%;
            }
          }

          span.btns {
            display: flex;
            align-items: center;
            font-family: $regular-font;

            @media (max-width: 1100px) {
              flex-direction: column;
              align-items: flex-start;
            }

            span,
            a {
              font-size: 24px;

              @media (max-width: 600px) {
                font-size: 20px;
              }
            }

            span {
              margin: 0 0 0 0.7em;

              @media (max-width: 1100px) {
                margin: 0.6em 0 0.4em;
              }

              @media (max-width: 767px) {
                margin: 0.6em auto 0.4em;
              }
            }

            a {
              color: #396cf9;
              transition: 0.3s ease;

              @media (min-width: 1025px) {
                &:hover {
                  text-decoration: none !important;
                  color: $text-blue;
                }
              }
            }
          }

          .success {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 7%;
            background: #1a1919;

            .close {
              position: absolute;
              right: 15px;
              top: 15px;
              cursor: pointer;
            }

            .row {
              display: flex;
              align-items: center;
              width: 100%;

              &-h {
                margin-bottom: 40px;

                @media (max-width: 1124px) {
                  flex-direction: column;
                  text-align: center;
                  margin-bottom: 20px;
                }
              }

              &-b {
                font-family: $museo-font;
                font-size: 22px;
                line-height: 150%;

                @media (min-width: 1025px) {
                  max-width: 85%;
                }

                @media (max-width: 1124px) {
                  font-size: 18px;
                }

                .your-email {
                  color: #4471ec;
                }
              }

              & > span {
                display: flex;
                flex-direction: column;
              }
            }

            &-img {
              max-width: 20%;
              margin-right: 20px;

              @media (max-width: 1124px) {
                max-width: 30%;
                margin-right: 0;
                margin-bottom: 20px;
              }

              img {
                width: 100%;
              }
            }

            &-h {
              font-size: 40px;
              line-height: 120%;
              font-family: $regular-font;

              @media (max-width: 1124px) {
                font-size: 30px;
              }
            }
          }
        }
      }
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    position: relative;

    img.head {
      display: block;
      position: absolute;
      width: 50%;
      max-width: 600px;
      transform: rotateY(180deg) translateY(45%);
      right: 0;
      bottom: 0;

      @media (max-width: 600px) {
        width: 80%;
      }
    }

    .left {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 70px;

      @media (max-width: 600px) {
        padding-bottom: 120px;
      }

      a {
        color: $text-egg;
        text-decoration: none !important;
        transition: 0.3s ease;

        &.mail {
          font-size: 30px;

          @media (min-width: 1025px) {
            &:hover {
              color: $text-yellow;
            }
          }

          @media (max-width: 600px) {
            font-size: 20px;
          }

          img {
            margin-right: 0.5em;
            margin-bottom: -0.25em;

            @media (max-width: 600px) {
              max-width: 30px;
            }
          }
        }
      }

      p {
        font-family: $second-font;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 0;

        @media (max-width: 600px) {
          font-size: 14px;
        }
      }

      .address {
        padding: 30px 0;

        @media (max-width: 600px) {
          padding: 20px 0;
        }
      }

      .social {
        a {
          display: flex;
          float: left;
          width: 48px;
          height: 48px;
          align-items: center;

          @media (max-width: 600px) {
            width: 32px;
            height: 32px;
          }

          &:first-child {
            margin-right: 12px;

            @media (max-width: 600px) {
              margin-right: 8px;
            }
          }

          img {
            @media (max-width: 600px) {
              max-width: 30px;
            }
          }

          &:last-of-type {
            img {
              @media (max-width: 600px) {
                max-width: 32px;
              }
            }
          }
        }
      }
    }
  }
}
</style>