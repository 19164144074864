<template>
  <div
    ref="homeRoot"
    class="home"
    :style="'--head-extra: ' + headExtraOffset + 'px; --hwd-offset: ' + hwdOffset + 'px; --hwd3offset: ' + offsetStart + 'px;'"
  >
    <HeaderBar />
    <HomeSection />
    <WhatWeDoSection />
    <!--    <HowWeDoSection />-->
    <HowWeDoSection1 />
    <SlideSmootherSection />
    <HowWeDoSection2 />
    <SlideSmootherSection />
    <HowWeDoSection3 />
    <!--    <SlideSmootherSection />-->
    <ClientsSection />
    <!--    <SlideSmootherSection />-->
    <GetStartedSection />
    <!--    <SlideSmootherSection />-->
    <!--    <SlideSmootherSection />-->
    <ContactUsSection />
  </div>
</template>

<script>
// import ScrollDepthAnalytics from "@/mixins/ScrollDepthAnalytics";
import HeaderBar from "@/components/Content/HeaderBar.vue";
import HomeSection from "@/components/Content/HomeSection.vue";
import WhatWeDoSection from "@/components/Content/WhatWeDoSection.vue";
// import HowWeDoSection from "@/components/Content/HowWeDoSection.vue";
import GetStartedSection from "@/components/Content/GetStartedSection.vue";
import ContactUsSection from "@/components/Content/ContactUsSection.vue";
import HowWeDoSection1 from "@/components/Content/HowWeDoSection1.vue";
import HowWeDoSection2 from "@/components/Content/HowWeDoSection2.vue";
import HowWeDoSection3 from "@/components/Content/HowWeDoSection3.vue";
import SlideSmootherSection from "@/components/Content/SlideSmootherSection.vue";
import ClientsSection from "@/components/Content/ClientsSection.vue";

export default {
  name: "HomePage",
  components: {
    ClientsSection,
    SlideSmootherSection,
    HowWeDoSection3,
    HowWeDoSection2,
    HowWeDoSection1,
    ContactUsSection,
    GetStartedSection,
    // ClientsSection,
    // HowWeDoSection,
    WhatWeDoSection,
    HeaderBar,
    HomeSection
  },
  data() {
    return {
      headExtraOffset: 0,
      hwdOffset: 0,
      offsetStart: 0
    }
  },
  // mixins: [ScrollDepthAnalytics],
  methods: {
    checkScrollForTopBg() {
      this.headExtraOffset = document.getElementById('who_we_are').clientHeight / 2
      window.scrollY > 10 ? document.body.classList.add('scrolled') : document.body.classList.remove('scrolled');
      window.scrollY > this.headExtraOffset ? document.body.classList.add('fix-head') : document.body.classList.remove('fix-head')
      const hwd3top = document.getElementById('how_we_do_3').getBoundingClientRect().top
      const offsetStart = 160
      this.offsetStart = offsetStart

      if (hwd3top < offsetStart) {
        document.body.classList.add('scroll-hwd')
        this.hwdOffset = hwd3top >= 0 ? -1 * (offsetStart - hwd3top) : -1 * offsetStart + hwd3top;
      } else {
        document.body.classList.remove('scroll-hwd')
        this.hwdOffset = 0
      }

      // const cond1 = window.innerHeight / 2
      // const cond2 = window.innerHeight / 3
      //
      // const heroTop = this.heroSection.getBoundingClientRect().top
      // const heroHeight = this.heroSection.offsetHeight
      // if (heroTop < cond1 && heroTop + heroHeight > cond2) {
      //   document.body.classList.add('hero-active')
      // } else {
      //   document.body.classList.remove('hero-active')
      // }
      //
      // const wwdTop = this.wwdSection.getBoundingClientRect().top
      // const wwdHeight = this.wwdSection.offsetHeight
      // if (wwdTop < cond1 && wwdTop + wwdHeight > cond2) {
      //   document.body.classList.add('wwd-active')
      // } else {
      //   document.body.classList.remove('wwd-active')
      // }
      //
      // const hwdTop = this.hwdSection.getBoundingClientRect().top
      // const hwdHeight = this.hwdSection.offsetHeight
      // if (hwdTop < cond1 && hwdTop + hwdHeight > cond2) {
      //   document.body.classList.add('hwd-active')
      // } else {
      //   document.body.classList.remove('hwd-active')
      // }
      //
      // const hwd1Top = this.hwd1Section.getBoundingClientRect().top
      // const hwd1Height = this.hwd1Section.offsetHeight
      // if (hwd1Top < cond1 && hwd1Top + hwd1Height > cond2) {
      //   document.body.classList.add('hwd1-active')
      // } else {
      //   document.body.classList.remove('hwd1-active')
      // }
      //
      // const hwd2Top = this.hwd2Section.getBoundingClientRect().top
      // const hwd2Height = this.hwd2Section.offsetHeight
      // if (hwd2Top < cond1 && hwd2Top + hwd2Height > cond2) {
      //   document.body.classList.add('hwd2-active')
      // } else {
      //   document.body.classList.remove('hwd2-active')
      // }
      //
      // const hwd3Top = this.hwd3Section.getBoundingClientRect().top
      // const hwd3Height = this.hwd3Section.offsetHeight
      // if (hwd3Top < cond1 && hwd3Top + hwd3Height > cond2) {
      //   document.body.classList.add('hwd3-active')
      // } else {
      //   document.body.classList.remove('hwd3-active')
      // }
      //
      // const clientsTop = this.cliensSection.getBoundingClientRect().top
      // const clientsHeight = this.cliensSection.offsetHeight
      // if (clientsTop < cond1 && clientsTop + clientsHeight > cond2) {
      //   document.body.classList.add('clients-active')
      // } else {
      //   document.body.classList.remove('clients-active')
      // }
      //
      // const getStartedTop = this.getStartedSection.getBoundingClientRect().top
      // const getStartedHeight = this.getStartedSection.offsetHeight
      // if (getStartedTop < cond1 && getStartedTop + getStartedHeight > cond2) {
      //   document.body.classList.add('get-started-active')
      // } else {
      //   document.body.classList.remove('get-started-active')
      // }
      //
      // const contactUsTop = this.contactUsSection.getBoundingClientRect().top
      // const contactUsHeight = this.contactUsSection.offsetHeight
      // if (contactUsTop < cond1 && contactUsTop + contactUsHeight > cond2) {
      //   document.body.classList.add('contact-us-active')
      // } else {
      //   document.body.classList.remove('contact-us-active')
      // }
    }
  },
  mounted() {
    // this.heroSection = document.getElementById('who_we_are')
    // this.wwdSection = document.getElementById('what_we_do')
    // this.hwdSection = document.getElementById('how_we_do')
    // this.hwd1Section = document.querySelector('#how_we_do .el-1')
    // this.hwd2Section = document.getElementById('how_we_do_2')
    // this.hwd3Section = document.getElementById('how_we_do_3')
    // this.cliensSection = document.getElementById('clients')
    // this.getStartedSection = document.getElementById('get_started')
    // this.contactUsSection = document.getElementById('contact_us')
    this.checkScrollForTopBg()
    window.addEventListener('scroll', this.checkScrollForTopBg)
    window.addEventListener('resize', this.checkScrollForTopBg)
    window.addEventListener('orientationchange', this.checkScrollForTopBg)
  },
};
</script>
