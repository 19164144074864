<template>
  <div
    id="how_we_do"
    class="how-we-do container-full flex flex-col"
    :class="{'screentest': $store.state.Screentest.isScreentest}"
  >
    <div class="container-full flex anim0">
      <div class="container h1-cont">
        <h1
          class="fade-in"
          :class="startAnimation0 ? 'fade-in-start' : 'fade-in-wait'"
        >
          How We Do
        </h1>
      </div>
    </div>
    <div class="container-full el el-1 flex flex-1 flex-col justify-around">
      <div class="container">
        <img
          src="@/assets/img/img-how-we-do-1.png"
          alt=""
          class="anim1 fade-from-left"
          :class="startAnimation ? 'fade-from-left-start' : 'fade-from-left-wait'"
        >
        <div
          style="transition-delay: 0.2s;"
          class="fade-from-right"
          :class="startAnimation ? 'fade-from-right-start' : 'fade-from-right-wait'"
        >
          <div class="flex flex-col width-auto">
            <h2>Adaptability<br>and Autonomy</h2>
            <p>Providing <span class="text-2x text-green">White Label</span> service</p>
            <p>We meet <span class="text-2x text-green">Tight Deadlines</span></p>
            <p>On-Demand <span class="text-2x text-green">Scalable</span> resources</p>
            <p><span class="text-2x text-green">Full Cycle</span> development</p>
            <p><span class="text-2x text-green">Independent</span> project management<br>to unburden your mind</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export default {
  name: "HowWeDoSection1",
  data() {
    return {
      startAnimation0: false,
      startAnimation: false,
    };
  },
  computed: {
    ...mapState(["UserAgent"]),
  },
  methods: {
    initAnimations() {
      ScrollTrigger.create({
        trigger: ".anim0",
        start: "top bottom-=10%",
        onEnter: () => {
          this.startAnimation0 = true;
        },
      });

      ScrollTrigger.create({
        trigger: ".anim1",
        start: "top bottom-=20%",
        onEnter: () => {
          this.startAnimation = true;
        },
      });
    }
  },
  mounted() {
    setTimeout(this.initAnimations, 1500)
  },
};
</script>

<style lang="scss" scoped>
.how-we-do {
  position: relative;
  overflow: hidden;
  padding: 74px 0;

  /* background: no-repeat url("~@/assets/img/bg-how-we-do-1.jpg"), #000;
  background-size: 100% 100%; */
  background: #000;
  box-shadow: 0 0 100px rgb(0 0 0 / 100%);

  /* @media (min-width: 1025px) {
    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgb(0 0 0 / 0%);
      transition: $fade-transition;

      body:not(.hwd-active) & {
        background: $fade-color;
      }
    }
  } */

  @media (max-width: 600px) {
    padding: 60px 0 50px;
  }

  @media (min-width: 1025px) {
    background-size: 100% 300px;
    position: sticky;
    top: var(--hwd-offset);
    min-height: 100vh;
    overflow: auto;
  }

  @media (min-width: 601px) {
    .mobile-br {
      display: none;
    }
  }

  h1 {
    margin: 0 0 30px;
    color: $text-egg !important;

    @media (min-width: 1200px) and (min-height: 720px) {
      &.fade-in-start {
        transform: scale(1.2) !important;
      }
    }

    @media (max-width: 600px) {
      margin-top: -10px;
      margin-bottom: 50px;
    }
  }

  .el {
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      & > div {
        max-width: 50%;
        min-width: 50%;

        @media (min-width: 1200px) and (min-height: 720px) {
          &.fade-from-right-start {
            transform: scale(1.2) translateX(-10%) !important;
          }

          &.fade-from-left-start {
            transform: scale(1.2) translateX(10%) !important;
          }
        }

        p {
          margin-bottom: 0.7em;
        }

        @media (min-width: 601px) and (max-width: 767px) {
          max-width: 65%;
          min-width: 65%;
        }

        @media (max-width: 600px) {
          max-width: 100%;
          min-width: 100%;

          p {
            font-size: 16px;
            margin-bottom: 12px;
          }

          .text-2x {
            font-size: 20px;
          }
        }

        .flex-p {
          display: flex;
          flex-direction: row;
          align-items: center;

          & > *:first-child {
            margin-right: 0.3em;
          }
        }
      }

      & > img {
        max-width: 35%;
        max-height: 90%;
        margin: 0 auto;

        @media (max-width: 767px) {
          max-width: 30%;
          margin: 0;
        }

        @media (max-width: 600px) {
          position: absolute;
        }
      }
    }

    &-1 {
      position: relative;

      /* @media (min-width: 1025px) {
         transition: 0.3s ease-in-out;

         body.hwd2-active &,
         body.hwd3-active & {
           opacity: 0 !important;
         }

         &::after {
           position: absolute;
           content: "";
           top: 0;
           left: 0;
           right: 0;
           bottom: 0;
           background: rgb(0 0 0 / 0%);
           transition: $fade-transition;

           body:not(.hwd1-active) & {
             background: $fade-color;
           }
         }
       } */

      @media (min-width: 601px) {
        background: no-repeat url("~@/assets/img/bg-how-we-do-sub-1-2.jpg");
        background-size: 100% 100%;

        .width-auto {
          width: fit-content;
          float: right;
          margin: 0;

          & > * {
            width: auto;
          }
        }
      }

      @media (min-width: 1025px) {
        img {
          max-height: 90%;
        }

        .width-auto {
          float: none !important;
          margin: 0 auto !important;

          & > *:not(h2) {
            padding-left: 100px;
          }
        }
      }

      @media (max-width: 600px) {
        img {
          right: 7%;
          top: -10px;
          transform: rotateY(180deg);
        }
      }
    }

    &-2 {
      padding: 37px 0;
      background: url("~@/assets/img/bg-how-we-do-2.jpg"), #000;
      background-size: 100% 100%;

      @media (max-width: 600px) {
        img {
          right: 5%;
          top: 20%;
          transform: rotateY(180deg);
        }
      }
    }

    &-3 {
      padding: 37px 0 74px;
      background: url("~@/assets/img/bg-how-we-do-3.jpg"), #000;
      background-size: 100% 100%;

      @media (max-width: 600px) {
        padding: 50px 0;

        img {
          right: 7%;
          top: -30px;
        }
      }
    }
  }
}
</style>