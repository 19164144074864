<template>
  <div
    id="who_we_are"
    class="who-we-are container-full flex"
    :class="{'screentest': $store.state.Screentest.isScreentest}"
  >
    <video v-if="UserAgent.window.width > 767" id="background-video" autoplay loop muted src="@/assets/video/hero-bg.mp4" />
    <div class="container container-fhd flex flex-col flex-1 justify-around">
      <h1
        class="slide-from-top"
        :class="startAnimation ? 'slide-from-top-start' : 'slide-from-top-wait'"
      >
        We’re a seasoned<br><span>Digital Pharma Team</span>
      </h1>
      <div class="home-content">
        <div
          style="transition-delay: 0.6s;"
          class="descr fade-from-bottom"
          :class="startAnimation ? 'fade-from-bottom-start' : 'fade-from-bottom-wait'"
        >
          <!--p>
            Last 8 years we operated as White Label developers for US pharma, offering exeptional reliability, quality and cost-effectiveness.
          </p>
          <button
            class="btn"
            @click="scrollToContactUs"
          >
            Contact Us
          </button-->
        </div>
        <div
          style="transition-delay: 1s;"
          class="list fade-from-bottom"
          :class="startAnimation ? 'fade-from-bottom-start' : 'fade-from-bottom-wait'"
        >
          <ul>
            <li>Web and Video outsource for US Pharma</li>
            <li>10 years as Self-Sufficient-Dedicated-White-Label Team</li>
            <li>We made projects for more than 80 Pharma Companies</li>
            <li>Best European talents synchronized with your work schedule</li>
            <li>Industry know-how</li>
            <li>Data security & compliance</li>
            <li>Live transparency and reliable documentation</li>
            <li>Smooth two-way vendor transitions</li>
          </ul>
        </div>
      </div>
      <img
        style="transition-delay: 0s;"
        class="head fade-in-o"
        :class="startAnimation ? 'fade-in-o-start' : 'fade-in-o-wait'"
        src="@/assets/img/NEW-heads-left-lower.png"
        alt=""
      >
    </div>
    <!--img
      class="bg-angle"
      src="@/assets/img/bg-home-angle.png"
      alt=""
    -->
  </div>
</template>

<script>

import {mapState} from "vuex";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export default {
  name: "HomeSection",
  data() {
    return {
      startAnimation: false
    };
  },
  computed: {
    ...mapState(["UserAgent"]),
  },
  methods: {
    scrollToContactUs(e) {
      e.preventDefault()
      e.stopImmediatePropagation()
      const coef = window.innerWidth < 1025 ? 30 : 40;
      const top  = document.getElementById("contact_us").offsetTop - coef
      setTimeout(() => {
        window.scrollTo({top: top, behavior: 'smooth'});
      }, 0)
    },
    initAnimations() {
      let interval = setInterval(() => {
        if (document.getElementById('preloader-panel') === null) {
          this.startAnimation = true
          clearInterval(interval)
        }
      }, 500)
    }
  },
  mounted() {
    setTimeout(this.initAnimations, 1000)
  },
};
</script>

<style lang="scss" scoped>
.who-we-are {
  display: flex;
  position: relative;
  overflow: hidden;
  padding-top: 120px;
  padding-bottom: 0;

  @media (min-width: 1025px) {
    height: 100vh;
    max-height: 960px;
  }

  .container-fhd {
    position: relative;
    padding-bottom: 80px;
  }

  /* @media (min-width: 1025px) {
    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgb(0 0 0 / 0%);
      transition: $fade-transition;

      body:not(.hero-active) & {
        background: $fade-color;
      }
    }
  } */

  background-image: url("~@/assets/img/1st-bg.jpg");
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;

  @media (max-width: 767px) {
    background-size: cover;
    background-position: left -50px bottom;
    padding-top: 70px;
  }

  & > video {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    min-width: 100%;
    min-height: 100%;
  }

  h1 {
    position: relative;
    color: $text-egg !important;

    span {
      font-size: 1.48em;

      @media (max-width: 1024px) {
        font-size: 1.2em;
      }
    }

    @media (min-width: 767px) and (max-height: 719px) {
      margin-bottom: 60px;
    }

    @media (min-width: 1025px) and (min-height: 720px) {
      margin-bottom: 80px;

      &.slide-from-top-start {
        transform: translateY(0) scale(1);
      }
    }

    @media (min-width: 1025px) {
      font-size: calc(100vw / 18);
      margin-bottom: 30px;
    }

    @media (min-width: 1920px) {
      font-size: 97px;
    }

    @media (max-width: 399px) {
      font-size: 36px;
    }
  }

  .home-content {
    display: flex;
    min-width: 100%;
    max-width: 100%;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }

    .descr,
    .list {
      font-family: $museo-font;
      display: flex;
      flex-direction: column;
      width: 45%;
      font-size: 18px;
      justify-content: flex-end;

      @media (max-width: 767px) {
        width: 100%;
      }

      @media (min-width: 1025px) {
        width: 38%;
      }
    }

    .descr {
      position: relative;
      z-index: 1;
      font-size: 20px;
      line-height: 150%;

      p {
        margin-bottom: 0;
      }

      .btn {
        font-family: $second-font;
        position: absolute;
        bottom: -80px;
        left: 0;
        z-index: 1;
        color: #fff;
        border: 2px solid #fff;

        @media (max-width: 640px) {
          padding: 0.5em 2em;
        }

        @media (min-width: 1025px) {
          &:hover {
            color: $text-yellow;
            border-color: $text-yellow;
          }
        }
      }
    }

    .list {
      @media (max-width: 640px) {
        font-size: 16px;
      }

      @media (max-width: 600px) {
        margin-bottom: 50px;
      }

      li::before {
        background-image: url("~@/assets/img/check-circle.svg");
      }
    }
  }

  .head {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-100%, -55%);
    width: calc($container / 2 - $container-padding);
    max-width: 45%;

    @media (min-width: 1025px) {
      width: 55%;
      max-width: 960px;
      transform: translate(-90%, -55%);
    }

    @media (min-width: 1200px) {
      width: 50%;
    }

    @media (max-width: 767px) {
      transform: rotateY(180deg) translateY(-55%);
      left: unset;
      right: 20px;
    }

    @media (max-width: 600px) {
      width: calc(80% - 40px);
      max-width: calc(80% - 40px);
    }

    @media (min-width: 1025px) {
      body.fix-head & {
        position: fixed;

        /* bottom: calc(40vh + 70px - var(--head-extra)); */

        /* top: 60%; */
        top: var(--head-extra);
      }
    }
  }

  .bg-angle {
    position: absolute;
    bottom: 0;
    left: 0;
    min-width: 100%;
    max-width: 100%;
  }
}
</style>